.file-input-container {
  position: relative;
  display: inline-block;
}

.file-input {
  opacity: 0;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  user-select: none;
}
